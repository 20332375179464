<template>
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9182 11.3219C14.8535 11.2493 14.79 11.1766 14.7277 11.1065C13.8706 10.1395 13.3521 9.55588 13.3521 6.81849C13.3521 5.40128 12.9886 4.23845 12.2721 3.36632C11.7439 2.72203 11.0298 2.23328 10.0885 1.87207C10.0764 1.86579 10.0656 1.85754 10.0566 1.84773C9.71802 0.790271 8.79158 0.0820312 7.74671 0.0820312C6.70184 0.0820312 5.77579 0.790271 5.43724 1.84664C5.42821 1.85609 5.41754 1.86408 5.40569 1.87026C3.2092 2.71368 2.14174 4.33184 2.14174 6.8174C2.14174 9.55588 1.62398 10.1395 0.766109 11.1054C0.703776 11.1755 0.640273 11.2467 0.575602 11.3208C0.408548 11.5088 0.302705 11.7374 0.2706 11.9797C0.238495 12.2219 0.281472 12.4677 0.394444 12.6879C0.634819 13.1603 1.14712 13.4536 1.73189 13.4536L13.7658 13.4536C14.3479 13.4536 14.8567 13.1607 15.0978 12.6905C15.2113 12.4702 15.2547 12.2242 15.2228 11.9817C15.191 11.7391 15.0853 11.5102 14.9182 11.3219ZM7.74671 16.3617C8.30968 16.3613 8.86202 16.2188 9.34517 15.9492C9.82831 15.6797 10.2242 15.2932 10.491 14.8308C10.5035 14.8086 10.5097 14.7838 10.509 14.7588C10.5082 14.7337 10.5005 14.7092 10.4866 14.6878C10.4728 14.6663 10.4532 14.6486 10.4298 14.6362C10.4063 14.6239 10.3799 14.6175 10.353 14.6175L5.14116 14.6175C5.11425 14.6174 5.08777 14.6238 5.06431 14.6361C5.04084 14.6484 5.02119 14.6661 5.00727 14.6876C4.99334 14.7091 4.98562 14.7336 4.98484 14.7587C4.98407 14.7838 4.99028 14.8086 5.00286 14.8308C5.26954 15.2932 5.66541 15.6796 6.14848 15.9492C6.63155 16.2187 7.18381 16.3613 7.74671 16.3617Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconBell'
}
</script>
